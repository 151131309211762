import { API_CONFIG } from 'prosumer-app/libs/eyes-core';
import { ApiConfig } from 'prosumer-app/libs/eyes-shared';
import { parseTemplate } from 'url-template';

import { inject, Injectable } from '@angular/core';

import {
  AffectedEntitiesDetailsKeysType,
  Endpoints,
  ListSubResourcesParams,
  ScenarioParentage,
  SingleSubResourceParams,
} from './endpoint-provider.models';

@Injectable({ providedIn: 'root' })
export class EndpointProvider {
  envApi = inject<ApiConfig>(API_CONFIG);

  forSubResources(params: ListSubResourcesParams): string {
    return parseTemplate(this.getSubResourcesTemplate()).expand(
      params as unknown as Record<string, string>,
    );
  }

  forSubResource(params: SingleSubResourceParams): string {
    return parseTemplate(this.getSubResourceTemplate()).expand(
      params as unknown as Record<string, string>,
    );
  }

  forCoherenceCheck(params: ScenarioParentage): string {
    return parseTemplate(this.getCoherenceCheckTemplate()).expand(
      params as unknown as Record<string, string>,
    );
  }

  forAffectedEntitiesDetails(keys: AffectedEntitiesDetailsKeysType): string {
    return parseTemplate(this.getAffectedEntitiesDetailTemplate()).expand(
      keys as unknown as Record<string, string>,
    );
  }

  forAffectedEntitiesFromScenario(params: ScenarioParentage): string {
    return parseTemplate(
      this.buildAffectedEntitiesFromScenarioEndpoint(),
    ).expand(params as unknown as Record<string, string>);
  }

  forUserPreferences(): string {
    return this.getUserPreferencesEndpoint();
  }

  forLinkWithTDB(): string {
    return this.buildEndpoint('transversal', 'generic');
  }

  forGeneralComments(params: ScenarioParentage): string {
    return parseTemplate(
      this.buildEndpoint('scenario', 'generalComments'),
    ).expand(params as unknown as Record<string, string>);
  }

  forGeneralComment(params: ScenarioParentage, commentId: string): string {
    return parseTemplate(
      this.buildEndpoint('scenario', 'generalComment'),
    ).expand({ ...params, commentId });
  }

  private buildAffectedEntitiesFromScenarioEndpoint(): string {
    return this.buildEndpoint('scenario', 'affectedEntitiesFromScenario');
  }

  private getAffectedEntitiesDetailTemplate(): string {
    return this.buildEndpoint('scenario', 'affectedEntitiesDetail');
  }

  private getUserPreferencesEndpoint(): string {
    return this.buildEndpoint('user', 'userPreferences');
  }

  private getCoherenceCheckTemplate(): string {
    return this.buildEndpoint('scenario', 'coherenceCheck');
  }

  private getSubResourceTemplate(): string {
    return this.buildEndpoint('scenario', 'subDetail');
  }

  private getSubResourcesTemplate(): string {
    return this.buildEndpoint('scenario', 'subDetails');
  }

  private buildEndpoint(key: string, subKey: string): string {
    return `${this.baseUrl}${this.endpoints[key][subKey]}`;
  }

  private get baseUrl(): string {
    return this.envApi.baseUrl;
  }

  private get endpoints(): Endpoints {
    return this.envApi.endpoints;
  }
}
